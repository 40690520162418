
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator'

@Component
export default class InputComponent extends Vue {
  @Prop({ required: false, default: false, }) readonly label!: string
  @Prop({ required: false, default: false, }) readonly id!: string
  @Prop({ required: false, default: false, }) readonly name!: string
  @Prop({ required: false, default: false, }) readonly placehoder!: string
  @Prop({ required: false, default: false, }) readonly icon!: string
  @Prop({ required: false, default: 'text', }) readonly type!: string
  @Prop({ required: false, default: '', }) readonly value_input!: string | number
  @Prop({ required: false, default: '', }) readonly messageInfo!: string
  @Prop({ required: false, default: false, }) readonly outline!: boolean
  @Prop({ required: false, default: false, }) readonly loading!: boolean
  @Prop({ required: false, default: false, }) readonly password!: boolean
  @Prop({ required: false, default: false, }) readonly iconAfter!: boolean
  @Prop({ required: false, default: false, }) readonly disabled!: boolean
  @Prop({ required: false, }) readonly valid!: boolean
  @Prop({ required: false, }) readonly error!: boolean
  private value: string | number = ''
  private hasVisiblePassword = false
  private debounce: null | ReturnType<typeof setTimeout> = null
  private testTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
    const test = 2000
    test
  }, 3000)

  mounted () {
    if (this.value_input !== '') {
      this.value = this.value_input
    }
  }

  @Watch('value')
  show (): void {
    this.$emit('value', this.value)
  }

  private submitKeyEnter () {
    this.$emit('keyEnter')
  }

  private debounceSearch () {
    clearTimeout(this.debounce ? this.debounce : this.testTimer)
    this.debounce = setTimeout(() => {
      this.$emit('watchValue', this.value)
    }, 600)
  }

  private showPassword () {
    this.hasVisiblePassword = !this.hasVisiblePassword
    this.$emit('changeIconPass', this.hasVisiblePassword)
  }
}
