
import { Component, Vue, } from 'vue-property-decorator'
import CreateComponent from './CreateDialog.vue'

@Component({
  components: {
    CreateComponent,
  },
  computed: {},
})
export default class DetailFrameworksComponent extends Vue {
  private goToControl () {
    this.$router.push('/app/frameworks/2/category/12')
  }
}
