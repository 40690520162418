import { render, staticRenderFns } from "./DetailFramework.vue?vue&type=template&id=6c0a3aae&scoped=true"
import script from "./DetailFramework.vue?vue&type=script&lang=ts"
export * from "./DetailFramework.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0a3aae",
  null
  
)

export default component.exports